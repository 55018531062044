function Product() {
  return (
    <main className="sec-content sec-content_3">
      <div className="container-def">
        <div className="sec-content__wrap">
          <div className="product-info">
            <div className="product-info__top">
              <div className="product-info__title title-def title-def_sec3">Жидкость кремнийорганическая гидрофобизирующая RUTESIL® 11 K</div>
              <div className="img-dec">
                <img src={window.location.origin+"/img/5.webp"} alt="" className="img-dec__img" />
              </div>
            </div>
            <div className="product-info__content wrap-article-2">
              <h4>Описание</h4>
              <p>Жидкость кремнийорганическая гидрофобизирующая Rutesil® 11К – это водный раствор метилсиликоната калия.</p>
              <p>Применяется в качестве модификатора буровых растворов при бурении нефтяных и газовых скважин, модифицирующей добавки к строительным материалам (бетону, штукатурке). Используется для гидрофобизирующей обработки пористых материалов, поверхностей из кирпича, мрамора, туфа, бетона, асбоцемента, черепицы, гипсовых изделий и других материалов подобного типа.</p>
              <p></p>
              <p>Обработка жидкостью:</p>
              <ul>
                <li>придает материалу отличные водоотталкивающие свойства (снижение водопоглощения до 25 раз);</li>
                <li>предотвращает появление «высолов»;</li>
                <li>повышает прочность материала;</li>
                <li>придает материалу морозо- и коррозионную стойкость, светопрочность, препятствует загрязнению поверхности;</li>
                <li>не изменяет внешнего вида материала, сохраняет газо- и воздухопроницаемость;</li>
                <li>повышает теплоизоляционные свойств поверхности;</li>
                <li>увеличивает срок службы зданий и сооружений;</li>
                <li>предотвращает рост микроорганизмов и лишайников на поверхности материала;</li>
              </ul>
              <p></p>
              <p>Выпускается 2-х марок: марка А и марка Б.</p>
              <p></p>
              <h4>Основные характеристики</h4>
              <p><strong>Марка А</strong></p>
              <p>Внешний вид:   прозрачная или слегка мутная жидкость, допускается мелкодисперсный осадок</p>
              <p>Массовая доля нелетучих веществ:   не менее 30%</p>
              <p>Щелочность в пересчёте на КОН:   не более 23%</p>
              <p>Плотность при температуре (20 ± 0,5) °С:   не менее 1,24 г/см куб.</p>
              <p></p>
              <p><strong>Марка Б</strong></p>
              <p>Внешний вид:  прозрачная или слегка мутная жидкость</p>
              <p>Массовая доля нелетучих веществ:   (52-56)%</p>
              <p>Щелочность в пересчёте на КОН:  (22,9-27)%</p>
              <p>Плотность при температуре (20 ± 0,5) °С:   (1,37-1,42) г/см куб.</p>
              <p></p>
              <h4>Упаковка</h4>
              <p>Контейнеры IBC 1 м куб.,  пластиковые или стальные бочки объемом 200 дм куб., 250 дм куб.</p>
              <p></p>
              <h4>Гарантийный срок хранения</h4>
              <p>1 год со дня изготовления.</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
export default Product;