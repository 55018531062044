function Product() {
  return (
    <main className="sec-content sec-content_3">
      <div className="container-def">
        <div className="sec-content__wrap">
          <div className="product-info">
            <div className="product-info__top">
              <div className="product-info__top-left">
                <div className="product-info__title title-def title-def_sec3">Эмульсия гидрофобизирующая<br/> RUTESIL® OE</div>
              </div>
              <div className="img-dec">
                <img src={window.location.origin+"/img/11.webp"} alt="" className="img-dec__img" />
              </div>
            </div>
            <div className="product-info__content wrap-article-2">
              <h4>Описание</h4>
              <p>Эмульсия RUTESIL® OE представляет собой эмульсию минерального масла в воде.</p>
              <p>Эмульсия предназначена для обеспыливания и дополнительной гидрофобизирующей обработки различных материалов, в том числе стекловолоконных и минераловатных изделий, обработанных фенолформальдегидной смолой.</p>
              <p>Используется в производстве минераловатных плит, базальтовой, минеральной ваты и стекловаты:</p>
              <ul>
                <li>для предотвращения образования мелкодисперсной пыли и придания водоотталкивающих свойств стекловолокнистым матам или матам из минеральной ваты;</li>
                <li>улучшает газо- и паропроницаемость материала;</li>
                <li>ускоряет и облегчает монтаж обработанных материалов;</li>
                <li>уменьшает пагубное воздействие пыли на организм человека в процессе контакта с минеральной ватой.</li>
              </ul>
              <p></p>
              <h4>Основные характеристики</h4>
              <p>Содержание активного вещества: не менее 50%.</p>
              <p>Условная вязкость при температуре (20 ± 0,5) °С:  (30 – 150) с.</p>
              <p>Стабильность при разведении:  не менее 12 ч.</p>
              <p></p>
              <h4>Упаковка</h4>
              <p>Контейнеры IBC объемом 1 м куб.</p>
              <p></p>
              <h4>Гарантийный срок хранения</h4>
              <p>Хранить следует при температуре от плюс 5°С до плюс 40°С в плотно закрытой таре изготовителя в закрытых складских помещениях, предохраняя их от попадания влаги, воздействия прямых солнечных лучей.</p>
              <p>Гарантийный срок хранения эмульсии в таре изготовителя – 6 месяцев со дня изготовления.</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
export default Product;