function Product() {
  return (
    <main className="sec-content sec-content_3">
      <div className="container-def">
        <div className="sec-content__wrap">
          <div className="product-info">
            <div className="product-info__top">
              <div className="product-info__top-left">
                <div className="product-info__title title-def title-def_sec2">Винная Кислота</div>
                <div className="product-info__title title-def title-def_sec3">RUTESIL® LTA</div>
              </div>
              <div className="img-dec">
                <img src={window.location.origin+"/img/15.webp"} alt="" className="img-dec__img" />
              </div>
            </div>
            <div className="product-info__content wrap-article-2">
              <h4>Описание</h4>
              <p>L(+)-Винная кислота Rutesil® LTA – это белый кристаллический порошок без запаха с кислым вкусом. </p>
              <p>Винная кислота в виде пищевой добавки Е334 используется в производстве консервов, джема, желе и различных кондитерских изделий, напитков. В медицине винную кислоту применяют в аналитической химии для обнаружения альдегидов, сахаров и др. В химической и фармакологической промышленности винная кислота применяется для разделения рацематов органических веществ на изомеры. Соли винной кислоты (тартраты) используются в медицине, а также при крашении тканей.</p>
              <p></p>
              <h4>Основные характеристики</h4>
              <p>Содержание основного вещества, %: 99.5 –101.0<br/>
              <br/>Относительное оптическое вращение [α]20D (C＝20%, H2O): +12,0 ГР~ +12,8ГР
              <br/>Точка плавления: 168 ~ 170℃
              <br/>Хлор, %: ≤0,01
              <br/>Оксалаты, %: ≤0,01
              <br/>Сульфаты, %: ≤0,015
              <br/>Кальций,% ≤0,02
              <br/>Потери при высушивании, %: ≤0,2
              <br/>Остаток после сгорания, %: ≤0,05
              <br/>Тяжелые металлы,%: ≤0,001
              <br/>Pb,%: ≤0,0002
              <br/>As, %: ≤0,0001
              <br/>Hg, %: ≤0,0001</p>
              <p></p>
              <h4>Упаковка</h4>
              <p>25kg 3 слойные бумажные мешки с ПЭ внутренними вкладышами на паллетах 100×120 cm, обтянутых ПЭ пленкой.
                <br/>500-1250kg ПП мешки с ПЭ внутренними вкладышами на паллетах 100×120 cm, обтянутых ПЭ пленкой</p>
              <p></p>
              <h4>Условия хранения</h4>
              <p>Хранить вне доступа воздуха, в защищенном от света, сухом и прохладном месте.</p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
export default Product;