function About() {
  return (
    <main className="sec-content">
      <div className="container-def">
        <div className="sec-content__wrap">
          <div className="about-info">
            <div className="about-info__left">
              <h1 className="title-def title-def_sec2 about-info__title color-g">О нас</h1>
              <div className="wrap-article-2">
                <p>Компания Рутесил была основана в 2005 году для разработки и производства высокотехнологичных силиконовых материалов, применяемых в машиностроении, строительстве, нефтедобыче, пищевой,  косметической и электротехнической отраслях промышленности.</p>
                <p>Силиконы обладают рядом уникальных качеств в комбинациях, отсутствующих у любых других известных веществ: например, способностью увеличивать или уменьшать адгезию, придавать гидрофобность, работать и сохранять свойства при экстремальных и быстроменяющихся температурах или повышенной влажности. Также они обладают диэлектрическими свойствами, биоинертностью, химической инертностью, эластичностью, долговечностью, экологичностью. Это обуславливает высокую востребованность силиконов  в разных областях.</p>
                <p>Мы поставляем продукцию более чем в 20 стран, включая Германию, Италию, Австрию, Францию, Великобританию, Бельгию, Финляндию, Испанию, Грецию, Чехию, Голландию, Латвию, Россию, Польшу, Грузию, Украину, Узбекистан, Азербайджан, Китай, Турцию.</p>
                <p>Компания работает в соответствии с действующим международным стандартом качества ISO 9001: 2008. Система ISO гарантирует не только высокое качество продукции, но и полную надежность обслуживания клиентов, а также других процессов деятельности нашей компании.</p>
              </div>
            </div>
            <div className="about-info__gal">
              <img src={window.location.origin+"/img/gal1.jpg"} alt="" className="about-info__gal-img" />
              <img src={window.location.origin+"/img/gal2.jpg"} alt="" className="about-info__gal-img" />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default About;